import React, { useEffect, useContext, useState, useCallback } from "react";
import { HashRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import Routes from "./components/Routes";
import AmplifyAuth from "./components/login/AmplifyAuth";
import theme from "./styles/theme";
// import SideBar from './components/SideBar/SideBar';
import { Helmet } from "react-helmet";
import config from "./config";
import ReactGA from "react-ga4";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Hidden, StyledEngineProvider } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { LayoutContext } from "./store/LayoutContext";
import { deepmerge } from "@mui/utils";
import SideBarDrawer from "./components/SideBar/SideBarDrawer";

import { ThemeProvider } from "@mui/material/styles";
//import { ThemeProvider } from "@mui/styles"; // Required for older styles

const App = () => {
  const { theme: databaseTheme } = useContext(LayoutContext);

  //console.log(databaseTheme);
  const [fullTheme, setFullTheme] = useState();
  const [sideBarOpen, setSideBarOpen] = useState(
    window.sessionStorage.getItem("sideBar")
      ? JSON.parse(window.sessionStorage.getItem("sideBar"))
      : false
  );

  useEffect(() => {
    if (config.ANALYTICS && process.env.NODE_ENV !== "development") {
      const isDev =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development";
      ReactGA.initialize(config.ANALYTICS, { debug: isDev });
      ReactGA.send("pageview");
      //console.log('initialize ga4')
    }
  }, []);

  //set to default theme - style sheet
  let applicationTheme = createTheme(theme);
  applicationTheme = responsiveFontSizes(applicationTheme);

  //create application theme callback, based on the theme
  const buildFullTheme = useCallback((databaseAppTheme) => {
    let fullTheme = createTheme(deepmerge(theme, databaseAppTheme));
    fullTheme = responsiveFontSizes(fullTheme);
    return fullTheme;
  }, []);

  useEffect(() => {
    //set to application database theme
    if (databaseTheme.app) {
      setFullTheme(buildFullTheme(databaseTheme.app));
    }
  }, [buildFullTheme, databaseTheme.app]);

  const openCloseSidebar = () => {
    setSideBarOpen(!sideBarOpen);
    window.sessionStorage.setItem("sideBar", JSON.stringify(!sideBarOpen));
  };

  // useEffect(() => {
  //   //console.log(sideBarOpen)
  // }, [sideBarOpen]);

  // console.log(applicationTheme);

  return (
    <ThemeProvider theme={applicationTheme}>
      <StyledEngineProvider injectFirst>
        <Helmet>
          <title>{config.NAME}</title>
          <meta
            name="description"
            content={`${config.NAME} - ${config.META_DESCRIPTION}`}
          />
        </Helmet>
        <HashRouter>
          <CssBaseline />
          {fullTheme || applicationTheme ? (
            <ThemeProvider theme={fullTheme || applicationTheme}>
              <AmplifyAuth
                loadedTheme={true}
                //loadedTheme={fullTheme ? true : false}
              >
                <Header />
                <Box
                  sx={{
                    padding: 0,
                    display: "flex",
                    background: fullTheme?.palette?.background?.lighter,
                  }}
                >
                  <Hidden mdDown>
                    <SideBarDrawer
                      sideBarOpen={sideBarOpen}
                      setSideBarOpen={openCloseSidebar}
                    />
                  </Hidden>
                  <Box
                    component="main"
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      position: "relative",
                      zIndex: 1,
                      overflow: "hidden",
                    }}
                  >
                    <Routes />
                  </Box>
                </Box>
              </AmplifyAuth>
            </ThemeProvider>
          ) : null}
        </HashRouter>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};
export default App;
