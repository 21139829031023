

import { Autocomplete, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import setLanguageText from '../../helpers/setLanguageText';
import { LayoutContext } from '../../store/LayoutContext';
import { LANGUAGE } from '../../store/LayoutReducers';

const LanguageSelect = () => {

    const options = [
        { label: 'English', id: 'en' },
        { label: 'German', id: 'de' },
    ];
    const { language, dispatchLanguage } = useContext(LayoutContext)
    const [ value, setValue] = useState(window.sessionStorage.getItem('language') ? JSON.parse(window.sessionStorage.getItem('language')) : language )

    useEffect(()=>{
        if(value){
        window.sessionStorage.setItem('language',JSON.stringify(value));
        dispatchLanguage({
            type: LANGUAGE,
            payload: value
        })
    }

    },[value,dispatchLanguage])

    return (
        <>
        <Autocomplete
            sx={{width:120}}
            disableClearable
            id="selected-language"
            value={value}
            options={options}
            getOptionLabel={option => option.label}
            onChange={(e, newValue) => {
                setValue(newValue);
            }}
            renderInput={params => (
                <TextField {...params} variant="outlined" label={setLanguageText(language,"Select Language")} />
            )}
        />
        </>
    );
};


export default LanguageSelect;
