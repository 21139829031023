const createAttributeOutputFunction = (attr, defaults, factored, factor) => {
  let attribute = {
    min: attr.one.attr_min,
    max: attr.one.attr_max,
  };
  let string = "switch(true){";
  let logic;

  //console.log(attr);
  //console.log(defaults);

  attr.two.attr_rules.forEach((rule) => {
    //console.log(rule);
    // beginning of each case
    string += "case ";

    rule.logic.forEach((ruleLogic) => {
      console.log(ruleLogic);
      logic = ruleLogic;
      //only needed for PH and CONC
      if (defaults) {
        switch (true) {
          case logic.value.includes("con"):
            logic.value.includes("max")
              ? (logic = {
                  ...logic,
                  value: factored
                    ? defaults.op_con_max / factor
                    : defaults.op_con_max,
                })
              : (logic = {
                  ...logic,
                  value: factored
                    ? defaults.op_con_min / factor
                    : defaults.op_con_min,
                });
            attribute = {
              ...attribute,
              min: defaults.op_con_min,
              max: defaults.op_con_max,
            };
            break;
          case logic.value.includes("ph"):
            logic.value.includes("max")
              ? (logic = { ...logic, value: defaults.ph_max })
              : (logic = { ...logic, value: defaults.ph_min });
            attribute = {
              ...attribute,
              min: defaults.ph_min,
              max: defaults.ph_max,
            };
            break;
          default:
            break;
        }
      }
      //added logic to stop error if not logic values is defined
      // this is the case logic
      string += `value ${logic.operator} ${logic.value ? logic.value : -1000}`;
      // if '&&' or '||' are selected, add it to the case, else end case ':'
      logic.connection ? (string += ` ${logic.connection}`) : (string += `:`);
    });
    // output will be a string ('Low', 'Medium'....) or the actual value (Number)
    let output = rule.output ? `'${rule.output}'` : "String(value)";
    // add the return in the case
    string += `return {min:${attribute.min}, max:${attribute.max}, output:${output}, status:${rule.status}};`;
  });
  // add the default case at the end of each dynamic cases
  string += `default: return {status: value == -1 ? -10 : value == -2 && -20 }}`;
  // return the function that will accept the value and build the logic with the string created above
  // eslint-disable-next-line
  //console.log(string)
  return Function("value", string);
};

export default createAttributeOutputFunction;
