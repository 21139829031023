import { darken } from "@mui/material";

import setLanguageText from "./setLanguageText";

export default function CreateChartData(
  machines,
  company_attributes,
  theme,
  company_colour_rules,
  language
) {
  let name = setLanguageText(language, "Meets All Requirements");
  let results = [
    {
      id: "totals",
      name: name,
      chart_type: "pie",
      data: {
        out: {
          count: 0,
          label: setLanguageText(language, "Out Control"),
          colour: theme.palette.error.main,
        },
        edge: {
          count: 0,
          label: setLanguageText(language, "On Edge"),
          colour: theme.palette.warning.main,
        },
        in: {
          count: 0,
          label: setLanguageText(language, "In Control"),
          colour: theme.palette.success.main,
        },
        none: {
          count: 0,
          label: "N/A",
          colour: theme.palette.background.light,
        },
        has_entries: {
          count: 0,
          label: setLanguageText(language, "Entries"),
          colour: theme.palette.background.light,
        },
        no_entries: {
          count: 0,
          label: setLanguageText(language, "No Entries"),
          colour: theme.palette.background.darker,
        },
      },
    },
  ];

  //console.log(company_attributes);
  if (company_attributes) {
    //creatre chart dat object
    company_attributes.forEach((attributes) => {
      //console.log(attributes)
      if (attributes?.four?.attr_chart === "pie") {
        let data;
        //console.log(attributes)

        // if (attributes.three.attr_form_output === 'radio') {
        //   data = attributes.three.attr_form_options.map((item, i) => {

        //     console.log(item)
        //     console.log(company_colour_rules)
        //     let colour = company_colour_rules.find(element => String(element.value) === String(item.value) )
        //     console.log(colour)

        //     //console.log(attributes.three.attr_form_options)
        //     return ({ 'count': 0, 'label': item.output, 'value': item.value, 'colour':  `${colour}`})
        //   })
        // } else {
        data = {
          out: {
            count: 0,
            label: setLanguageText(language, "Out Control"),
            colour: theme.palette.error.main,
          },
          edge: {
            count: 0,
            label: setLanguageText(language, "On Edge"),
            colour: theme.palette.warning.main,
          },
          in: {
            count: 0,
            label: setLanguageText(language, "In Control"),
            colour: theme.palette.success.main,
          },
          none: {
            count: 0,
            label: "N/A",
            colour: theme.palette.background.light,
          },
          no_entries: {
            count: 0,
            label: setLanguageText(language, "No Entries"),
            colour: theme.palette.background.darker,
          },
        };

        //}
        results.push({
          id: attributes.one.attr_id,
          name: attributes.one.attr_name,
          chart_type: attributes.four.attr_chart,
          data: data,
        });

        //console.log(results)

        // results.push({
        //   'id': attributes.one.attr_id,
        //   'name': attributes.one.attr_name,
        //   'chart_type': attributes.four.attr_chart,
        //   'data': {
        //     'out': { 'count': 0, 'label': 'Out Control', 'colour': theme.palette.error.main },
        //     'edge': { 'count': 0, 'label': 'On Edge', 'colour': theme.palette.warning.main },
        //     'in': { 'count': 0, 'label': 'In Control', 'colour': theme.palette.success.main },
        //     'none': { 'count': 0, 'label': 'N/A', 'colour': theme.palette.background.light },
        //     'no_entries': { 'count': 0, 'label': 'No Entries', 'colour': theme.palette.background.darker }
        //   }
        // })
      } else if (attributes?.four?.attr_chart === "bar") {
        let data;
        //console.log(attributes.three)

        if (attributes.three.attr_form_output === "radio") {
          data = attributes.three.attr_form_options.map((item, i) => {
            //console.log(attributes.three.attr_form_options)

            return {
              count: 0,
              label: item.output,
              value: item.value,
              colour: darken(theme.palette.success.main, `0.${i}`),
            };
          });
        } else if (attributes.three.attr_form_output === "dial") {
          data = [
            {
              count: 0,
              label: setLanguageText(language, "Out Control"),
              status: "30",
              colour: theme.palette.error.main,
            },
            {
              count: 0,
              label: setLanguageText(language, "On Edge"),
              status: "20",
              colour: theme.palette.warning.main,
            },
            {
              count: 0,
              label: setLanguageText(language, "In Control"),
              status: "10",
              colour: theme.palette.success.main,
            },
          ];
        } else {
          //if form output in a normal number input
          //console.log(attributes.two.attr_rules)
          data = attributes.two.attr_rules.map((item, i) => {
            //console.log(attributes.three.attr_form_options)
            return {
              count: 0,
              label: item.name,
              status: item.status,
              colour: darken(theme.palette.success.main, `0.${i}`),
            };
          });
        }
        results.push({
          id: attributes.one.attr_id,
          name: attributes.one.attr_name,
          chart_type: attributes.four.attr_chart,
          data: data,
        });
      }
    });
  }

  //console.log(results)

  if (machines && Object.values(machines).length) {
    //loop through custom attributes to create results

    let total_index = results.findIndex((item) => item.id === "totals");

    machines.forEach((machine) => {
      //console.log(machine)
      if (machine.last_entry.attributes_new) {
        //console.log(machine.last_entry.attributes_new)
        // get totals index
        if (total_index >= 0) {
          //add entry
          results[total_index].data.has_entries.count =
            results[total_index].data.has_entries.count + 1;

          //set total out of control
          if (machine.last_entry.out_of_control) {
            results[total_index].data.out.count =
              results[total_index].data.out.count + 1;
          } else {
            results[total_index].data.in.count =
              results[total_index].data.in.count + 1;
          }
        }
        // //parse attributes
        let attributes = machine.last_entry.attributes_new;

        //console.log(attributes)

        attributes.forEach((attribute) => {
          let string = attribute.attribute_id;
          //console.log(attribute);
          //console.log(results);

          let attr_index = results.findIndex((item) => item.id === string);
          //if chart type is a pie
          //console.log(results[attr_index])

          if (
            results[attr_index]?.chart_type &&
            results[attr_index].chart_type === "pie"
          ) {
            //console.log(results[attr_index])
            if (results[attr_index].data.out) {
              if (attribute.attribute_values.status === 30) {
                results[attr_index].data.out.count =
                  results[attr_index].data.out.count + 1;
              } else if (attribute.attribute_values.status === 20) {
                results[attr_index].data.edge.count =
                  results[attr_index].data.edge.count + 1;
              } else if (attribute.attribute_values.status === 10) {
                results[attr_index].data.in.count =
                  results[attr_index].data.in.count + 1;
              } else {
                results[attr_index].data.none.count =
                  results[attr_index].data.none.count + 1;
              }
            } else {
              let data_index = results[attr_index].data.findIndex(
                (item) =>
                  String(item.value) ===
                  String(attribute.attribute_values.value)
              );
              results[attr_index].data[data_index].count =
                results[attr_index].data[data_index].count + 1;
            }
          } else if (
            results[attr_index]?.chart_type &&
            results[attr_index].chart_type === "bar"
          ) {
            //console.log(attribute.attribute_values)
            let data_index = results[attr_index].data.findIndex(
              (data) =>
                String(data.value) === String(attribute.attribute_values.value)
            );
            //if the option is avaiable + 1
            if (data_index >= 0) {
              //console.log(results[attr_index].data[data_index].count)
              results[attr_index].data[data_index].count =
                results[attr_index].data[data_index].count + 1;
            } else {
              // this is for number inputs as the use status not value
              let data_index = results[attr_index].data.findIndex(
                (data) =>
                  String(data.status) ===
                  String(attribute.attribute_values.status)
              );
              if (data_index >= 0) {
                results[attr_index].data[data_index].count =
                  results[attr_index].data[data_index].count + 1;
              }
            }
          }
        });
      } else {
        if (total_index >= 0) {
          //add no entry
          results[total_index].data.no_entries.count =
            results[total_index].data.no_entries.count + 1;
        }
      }
    });
  }
  //console.log(results)
  return results;
}
