//set to live on build
const apiUrl = process.env.NODE_ENV !== "production" ? "dev" : "live";

//SAR config
const config = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: `https://4ezu04lfth.execute-api.eu-west-1.amazonaws.com/${apiUrl}`,
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_JcFLlrgIg",
    APP_CLIENT_ID: "2f5062br23jf0jo6e397084u5",
    IDENTITY_POOL_ID: "eu-west-1:087a7452-4ad4-4373-b86d-f6c4e4a0f103",
  },
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "sar-coolantcare",
  },
  SALTING: "CRYPTO GARAGE CHICKEN BOAT",
  INSTANCE: "sar",
  APPNAME: "sar",
  COPYRIGHT: "SAR Portal",
  TITLE: "SAR Portal",
  NAME: "SAR Portal",
  META_DESCRIPTION: "For all of SAR Portal.",
  //TIDIO: '//code.tidio.co/mgxsa3x2vm75udna9rc0vuztge5xfbsb.js',
  //TIDIOCHAT : 'https://www.tidio.com/talk/mgxsa3x2vm75udna9rc0vuztge5xfbsb',
  //ANALYTICS : 'G-1PWF80N6E1',
  KEY: "sar_key",
  LANGUAGE: { label: "English", id: "en" },
};

// // //rhenus config
//  const config = {
//   apiGateway: {
//     REGION: 'eu-central-1',
//     URL: `https://k9jgbealr2.execute-api.eu-central-1.amazonaws.com/${apiUrl}`
//   },
//   cognito: {
//     REGION: 'eu-central-1',
//     USER_POOL_ID: 'eu-central-1_H7SrPK6lY',
//     APP_CLIENT_ID: '3jg01iasesr9gl684pst8rggnc',
//     IDENTITY_POOL_ID: 'eu-central-1:686749f8-006a-43b5-a5d6-b1736dfd7e51'
//   },
//   MAX_ATTACHMENT_SIZE: 5000000,
//   s3: {
//     REGION: 'eu-central-1',
//     BUCKET: 'rhenus-checkcare'
//   },
//   SALTING: 'MONDAY JELLYFISH CARROT TREES',
//   INSTANCE: 'prod',
//   APPNAME: 'rhenus',
//   COPYRIGHT: 'rhenus Check & Care',
//   TITLE: '',
//   NAME: 'Check & Care',
//   META_DESCRIPTION: 'For all of your factory needs.',
//   //TIDIO: '//code.tidio.co/mgxsa3x2vm75udna9rc0vuztge5xfbsb.js',
//   //TIDIOCHAT : 'https://www.tidio.com/talk/mgxsa3x2vm75udna9rc0vuztge5xfbsb',
//   //ANALYTICS : 'G-1PWF80N6E1',
//   KEY:'rhenus_key',
//   //LANGUAGE:{ label: 'English', id: 'en' }
//   LANGUAGE:{ label: 'German', id: 'de' }
// };

export default config;
