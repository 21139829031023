import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Chip, Grid, Typography, Button } from '@mui/material';
import { MachineContext } from '../../../store/MachineContext';
import { CoolantContext } from '../../../store/CoolantContext';
import MachineDefaults from './MachineDefaults';
import CoolantSettings from './CoolantSettings';
import MachineProducts from '../../Products/Products/MachineProducts';
import { useHistory, useParams } from 'react-router-dom';
import fetchEntries from '../../../helpers/fetchEntries';
import { UserContext } from '../../../store/UserContext';
import {
  ADD_ENTRIES,
  SELECTED_MACHINE,
  SELECTED_MACHINES
} from '../../../store/MachineReducers';
import { fetchMachine } from '../../../helpers/fetchMachine';
import EntriesTable from './EntriesTable/EntriesTable';
import PageContent from '../../PageContent';
import PageHeader from '../../PageHeader';
import { CompanyContext } from '../../../store/CompanyContext';
import entriesTable_columns from './EntriesTable/entriesTable_columns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChartScatter, faPencilRuler, faPlus } from '@fortawesome/pro-light-svg-icons';
import SelectionRow from '../../Dashboard/Selection_Row/SelectionRow';
import { buttonStyles } from '../../../styles/buttonStyles';
import orderList from '../../../helpers/orderList';
import { useTheme } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import useTrampChart from '../../Charts/useTrampChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartCard from '../../Charts/ChartCard';
import useBacteriaChart from '../../Charts/useBacteriaChart';
import usePhChart from '../../Charts/usePhChart';
import useConcChart from '../../Charts/useConcChart';
import useFungiChart from '../../Charts/useFungiChart';
import LoadingScreen from '../../reusable/LoadingScreen';
import ExportSingleMachine from '../../Exports/ExportSingleMachine';
import AddCoolant from './AddCoolant';
import PrimaryBtnIcon from '../../buttons/PrimaryBtnIcon';
import AddEntry from '../../Entry/AddEntry';
import { isMobileOnly } from 'react-device-detect';
import EditMachine from './EditMachine';
import setLanguageText from '../../../helpers/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import MachineCharts from './MachineCharts';
import config from '../../../config';

const MachineDetails = () => {

  const buttonStyle = buttonStyles();
  const theme = useTheme();

  //****** CONTEXT ******
  const { dispatchSelectedMachine, selectedMachine, dispatchSelectedMachines } =
    useContext(MachineContext);

  const { selectedCoolant } = useContext(CoolantContext);
  const { authUser, user } = useContext(UserContext);
  const { selectedCompany } = useContext(CompanyContext);
  const { language } = useContext(LayoutContext)

  //****** STATE ******
  //const [entries, setEntries] = useState([]);
  //const [open, setOpen] = useState(false);
  const [plots, setPlots] = useState(true);
  const [tooltips, setTooltips] = useState(true);
  //const [tablePage, setTablePage] = useState(true);
  const [loading, setLoading] = useState(true);

  const { createTrampChart, trampData, trampOptions, trampChartRef } =
    useTrampChart({ plots, tooltips });
  const {
    createBacteriaChart,
    bacteriaData,
    bacteriaOptions,
    bacteriaChartRef
  } = useBacteriaChart({ plots, tooltips });

  const { createPhChart, phData, phOptions, phChartRef } = usePhChart({
    plots,
    tooltips
  });

  const { createConcChart, concData, concOptions, concChartRef } = useConcChart({
    plots,
    tooltips
  });

  const { createFungiChart, fungiData, fungiOptions, fungiChartRef } =
    useFungiChart({
      plots,
      tooltips
    });

  /*---- SET TO AND FROM DATES ----*/
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [open, setOpen] = useState(false);

  let { id } = useParams();

  //****** USE EFFECT ******
  // SET DATES FROM/TO

  //fetches machine entries based on the last enter date
  // useEffect(() => {
  //   console.log('set date')
  //   if (selectedMachine && Object.values(selectedMachine).length) {
  //     console.log(selectedMachine)
  //     let lastEntry = new Date(selectedMachine.last_entry.date_stamp)
  //     let lastYear = new Date(selectedMachine.last_entry.date_stamp).setMonth(lastEntry.getMonth() - 12);
  //     setDateFrom(lastYear);
  //     setDateTo(lastEntry.getTime());
  //   }
  // });

  // useEffect(() => 
  //   if (!dateFrom && !dateTo) {
  //     const today = new Date();
  //     const lastYear = new Date().setMonth(today.getMonth() - 24);
  //     setDateFrom(lastYear);
  //     setDateTo(today.getTime());
  //   }
  // }, [dateFrom, dateTo]);

  /*-- FETCH SELECTED MACHINE DETAIL ----*/
  useEffect(() => {
    // if reload page or access the page externally (Barcode)
    if (id && user.level) {
      setLoading(true);
      // fetch Machine details based on machine id from URL
      //console.log('here')
      fetchMachine(id, user, authUser.api_key).then(result => {
        //console.log(result)
        // save in context
        dispatchSelectedMachine({
          type: SELECTED_MACHINE,
          payload: {
            ...result[0],
            machine_settings: result[0]?.machine_settings && JSON.parse(result[0].machine_settings)
          }
        });

        //console.log(result)
        let lastEntry = new Date(JSON.parse(result[0].last_entry).date_stamp)
        let lastYear = new Date(JSON.parse(result[0].last_entry).date_stamp).setMonth(lastEntry.getMonth() - 12);
        //console.log(lastEntry)
        //console.log(lastYear)
        setDateFrom(lastYear);
        setDateTo(lastEntry.getTime());

        // save in context, this is used in Dashboard charts
        dispatchSelectedMachines({
          type: SELECTED_MACHINES,
          payload: {
            ...result[0],
            machine_settings: JSON.parse(result[0]?.machine_settings)
          }
        });
        setTimeout(() => {
          setLoading(false);
        }, 1000)

      });
    }
  }, [
    authUser.api_key,
    user,
    dispatchSelectedMachine,
    dispatchSelectedMachines,
    id
  ]);

  /*------------- FETCH ENTRIES ---------------*/

  useEffect(() => {
    // Wait for the fetched machine
    //console.log(dateFrom)
    //console.log(dateTo)
    if (dateFrom && dateTo) {
      // then ALL the entries between dates on each render , include voided
      setLoading(true);
      fetchEntries({
        machine_id: id,
        date_from: dateFrom,
        date_to: dateTo,
        api_key: authUser.api_key,
        voided: true
      }).then(entries => {
        dispatchSelectedMachine({
          type: ADD_ENTRIES,
          payload: entries
        });
        setLoading(false);
      });
    }
  }, [
    authUser.api_key,
    dispatchSelectedMachine,
    id,
    selectedMachine.machine_id,
    dateFrom,
    dateTo
  ]);

  //****** FUNCTIONS ******
  //fetch react table current visible page
  const getTableDataFn = useCallback(
    tablePage => {
      if (tablePage) {
        //setEntries(tablePage);
        createTrampChart(tablePage);
        createBacteriaChart(tablePage);
        createPhChart(tablePage);
        createConcChart(tablePage);
        createFungiChart(tablePage);
        //setTablePage(tablePage);
      }
    },
    [
      createBacteriaChart,
      createTrampChart,
      createPhChart,
      createConcChart,
      createFungiChart
    ]
  );
  const history = useHistory();
  //****** TABLE ******
  const data = useMemo(() => {
    return orderList(selectedMachine.entries) || [];
  }, [selectedMachine.entries]);

  /*------------- CREATE COLUMNS FOR TABLE ---------------*/

  const entriesColumns = useMemo(
    () => entriesTable_columns({ selectedCompany: selectedCompany, selectedMachine: selectedMachine, language: language, user: user }),
    [selectedCompany, selectedMachine, user]
  );

  //Show and hide plots
  const handlePlots = plots => {
    setPlots(prevState => !prevState);
  };
  //Show and hide tooltips
  const handleTooltips = plots => {
    setTooltips(prevState => !prevState);
  };

  // console.log('test')
   //console.log(concData)
  // console.log(fungiData)
  

  //****** RENDER ******
  return (
    <Grid item container direction="column">
      <PageHeader>
        {/* Fetch Companies */}
        <SelectionRow />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {!isMobileOnly &&
            <Grid item xs={12} style={{ marginBottom: theme.spacing(1) }}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Chip
                    label={setLanguageText(language, "Back to Machines")}
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    onClick={() => history.push('/machines')}
                  />
                </Grid>
                <Grid item>
                  <ExportSingleMachine
                    //tablePage={tablePage}
                    entries={selectedMachine.entries}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
          <Grid item xs={12}>
            <Grid item style={{ textAlign: 'center', marginTop: !isMobileOnly ? '-40px' : '0' }}>
              <Grid>
                <Typography style={{ display: 'inline-block' }} variant="h3">
                  {selectedMachine.machine_name}
                </Typography>
                {user.level > 7 &&
                  <EditMachine selectedMachine={selectedMachine} />
                }
              </Grid>
              <Typography>{selectedMachine.machine_group}</Typography>

            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <PageContent>
        <Grid container>
          {selectedMachine && (

            <Grid
              item
              container
              style={{ marginBottom: theme.spacing(1) }}
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <MachineDefaults selectedMachine={selectedMachine} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CoolantSettings selectedMachine={selectedMachine} />
              </Grid>
              <Grid item xs={12} >
                <MachineProducts />
              </Grid>
            </Grid>
          )}

          {data.length ?
            <>
              <Grid item xs={12} style={{ marginBottom: theme.spacing(1) }}>
                <Typography variant="h5" align={isMobileOnly ? 'center' : 'left'} >{setLanguageText(language, "Entries Table")}</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
                <EntriesTable
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                  columns={entriesColumns}
                  data={data}
                  getTableDataFn={getTableDataFn}
                  headerSettings={['out_of_control', 'factored']}
                />
              </Grid>
              <Grid item container spacing={1}>
                <Grid item container justifyContent="space-between">
                  <Grid item xs={12} sm={'auto'}>
                    <Typography variant="h5">{setLanguageText(language, "Charts")}</Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    spacing={1}
                    justifyContent="flex-end"
                  >
                    <Grid item xs={6} sm={'auto'}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handleTooltips}
                      >
                        <FontAwesomeIcon icon={faChartScatter} />
                        <Typography className={buttonStyle.buttonIconText}>
                          {setLanguageText(language, `${tooltips ? 'Hide' : 'Show'} Tooltips`)}
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={'auto'}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handlePlots}
                      >
                        <FontAwesomeIcon icon={faChartScatter} />
                        <Typography className={buttonStyle.buttonIconText}>
                          {setLanguageText(language, `${plots ? 'Hide' : 'Show'} Plots`)}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ marginBottom: theme.spacing(2) }}
                >

                  <ChartCard>
                    <Grid style={{ textAlign: 'center' }}>
                      <Typography variant="h6" >{setLanguageText(language, "Concentration levels")}</Typography>
                    </Grid>
                    <Line
                      ref={concChartRef}
                      data={concData}
                      options={concOptions}
                      plugins={[ChartDataLabels]}
                    />
                  </ChartCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ marginBottom: theme.spacing(2) }}
                >

                  <ChartCard>
                    <Grid style={{ textAlign: 'center' }}>
                      <Typography variant="h6" >{setLanguageText(language, "PH levels")}</Typography>
                    </Grid>
                    <Line
                      ref={phChartRef}
                      data={phData}
                      options={phOptions}
                      plugins={[ChartDataLabels]}
                    />
                  </ChartCard>
                </Grid>
                {config.APPNAME !== 'rhenus' ?
                  <>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      style={{ marginBottom: theme.spacing(2) }}
                    >
                      <ChartCard>
                        <Grid style={{ textAlign: 'center' }}>
                          <Typography variant="h6" >{setLanguageText(language, "Bacteria levels")}</Typography>
                        </Grid>
                        <Line
                          ref={bacteriaChartRef}
                          data={bacteriaData}
                          options={bacteriaOptions}
                          plugins={[ChartDataLabels]}
                        />
                      </ChartCard>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={4}
                      style={{ marginBottom: theme.spacing(2) }}
                    >
                      <ChartCard>
                        <Grid style={{ textAlign: 'center' }}>
                          <Typography variant="h6" >{setLanguageText(language, "Fungi levels")}</Typography>
                        </Grid>
                        <Line
                          ref={fungiChartRef}
                          data={fungiData}
                          options={fungiOptions}
                          plugins={[ChartDataLabels]}
                        />
                      </ChartCard>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      style={{ marginBottom: theme.spacing(2) }}
                    >
                      <ChartCard>
                        <Grid style={{ textAlign: 'center' }}>
                          <Typography variant="h6" >{setLanguageText(language, "Tramp oil levels")}</Typography>
                        </Grid>
                        <Line
                          ref={trampChartRef}
                          data={trampData}
                          options={trampOptions}
                          plugins={[ChartDataLabels]}
                        />
                      </ChartCard>
                    </Grid>
                  </>
                  : null}

              </Grid>
              {/* <MachineCharts/> */}
            </>

            : !selectedCoolant && !loading ?
              <Grid item xs={12} style={{ position: 'relative' }}>
                <LoadingScreen
                  loading={false}
                  background={theme.palette.background.lighter}
                  colour={theme.palette.text.secondary}
                  text="This machine has no coolant set"
                  position="absolute"
                  customAction={
                    <Grid container justifyContent="space-between" alignContent="center">
                      <AddCoolant selectedMachine={selectedMachine} />
                    </Grid>
                  }
                />
              </Grid>
              :
              <Grid item xs={12} style={{ position: 'relative' }}>
                <LoadingScreen
                  loading={false}
                  background={theme.palette.background.lighter}
                  colour={theme.palette.text.secondary}
                  text={loading ? "Loading machine details" : "This machine has no entries"}
                  position="absolute"
                  customAction={
                    !loading && (
                      <Grid container justifyContent="space-between" alignContent="center">
                        <Grid item style={{ margin: 'auto' }}>
                          <PrimaryBtnIcon
                            //disabled
                            icon={faPlus}
                            text="Add First Entry"
                            onClick={e => setOpen(true)}
                            className={buttonStyle.danger}
                          />
                        </Grid>
                        {open &&
                          <AddEntry
                            isDialog={open}
                            setIsDialog={setOpen}
                          />
                        }
                      </Grid>
                    )
                  }
                />
              </Grid>
          }
        </Grid>

      </PageContent>
    </Grid>
  );
};

export default MachineDetails;
