import React, { useContext } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  Button,
  Switch,
  Divider,
  Card,
  CardContent,
  CardActions,
  Chip,
} from "@mui/material";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import setLanguageText from "../../helpers/setLanguageText";
import { LayoutContext } from "../../store/LayoutContext";

export const FormOne = ({ compiledForm, isEdit }) => {
  const { setValue, control } = useFormContext();
  const { language } = useContext(LayoutContext);

  // useEffect(() => {
  //   console.log(compiledForm)
  //   if (compiledForm.one) {
  //     reset({ ...compiledForm.one });
  //   }
  // }, [reset, compiledForm.one]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="one.company_id"
          control={control}
          defaultValue=""
          rules={{ required: setLanguageText(language, "Company ID required") }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label={setLanguageText(language, "Company ID")}
              variant="outlined"
              value={value}
              disabled={true}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="one.attr_id"
          control={control}
          defaultValue=""
          rules={{
            required: setLanguageText(language, "Attribute ID required"),
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label={setLanguageText(language, "Attribute ID")}
              variant="outlined"
              value={value}
              disabled={true}
              onChange={onChange}
            />
          )}
        />
      </Grid>

      {/* This is the id for the attribute e.g conc or ph */}
      <Grid item xs={12} sm={6}>
        <Controller
          name="one.attr_name"
          control={control}
          defaultValue=""
          rules={{ required: setLanguageText(language, "Attribute Name") }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label={setLanguageText(language, "Attribute Name")}
              variant="outlined"
              value={value}
              onChange={(e) => {
                if (!isEdit) {
                  setValue(
                    "one.attr_id",
                    e.target.value.replace(/\s/g, "_").toLowerCase()
                  );
                }
                onChange(e.target.value);
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="attr_type_label">Value Type</InputLabel>
          <Controller
            name="one.attr_type"
            control={control}
            rules={{ required: setLanguageText(language, "Attribute Type") }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                label={setLanguageText(language, "Attribute Type")}
                labelId="attr_type_label"
              >
                <MenuItem value="int">
                  {setLanguageText(language, "Number")}
                </MenuItem>
                <MenuItem value="str">
                  {setLanguageText(language, "String")}
                </MenuItem>
                <MenuItem value="flt">
                  {setLanguageText(language, "Float")}
                </MenuItem>
              </Select>
            )}
            defaultValue="" // make sure to set up defaultValue
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="one.attr_min"
          control={control}
          defaultValue=""
          //rules={{ required: 'Attribute Min' }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              label={setLanguageText(language, "Minimum Value")}
              variant="outlined"
              type="number"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="one.attr_max"
          control={control}
          defaultValue=""
          //rules={{ required: 'Attribute Max' }}
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              type="number"
              label={setLanguageText(language, "Maximum Value")}
              variant="outlined"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Grid>

      <Grid item>
        <Controller
          name="one.attr_column"
          control={control}
          render={({ field: { onChange, value } }) => {
            //console.log(value)
            return (
              <FormControlLabel
                control={<Switch onChange={onChange} checked={value} />}
                label={setLanguageText(
                  language,
                  "Available in Columns & Reports"
                )}
              />
            );
          }}
          defaultValue={false}
        />
        <Controller
          name="one.attr_locked"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch onChange={onChange} checked={value} />}
              label={setLanguageText(
                language,
                "Locked Attribute (only available to admin)"
              )}
            />
          )}
          defaultValue={false}
        />
      </Grid>
    </Grid>
  );
};

export const FormTwo = ({ compiledForm, currentVariables }) => {
  const { language } = useContext(LayoutContext);
  const { register, control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "two.attr_rules",
    // this is an array of objects {title: '', id: ''}
  });

  // useEffect(() => {
  //   //console.log(compiledForm)
  //   if (compiledForm.two) {
  //     reset({ ...compiledForm.two });
  //   }
  // }, [reset, compiledForm.two]);

  return (
    <Grid item container xs={12} spacing={2}>
      {fields.map((item, index) => {
        //pass field name to child components
        const fieldName = `two.attr_rules[${index}]`;

        return (
          <Grid container item key={item.id}>
            <Card variant="outlined">
              <CardContent>
                <Grid container item spacing={2} xs={12}>
                  <Grid item xs={4}>
                    <Controller
                      name={`two.attr_rules[${index}].name`}
                      control={control}
                      defaultValue={item.name}
                      //rules={{ required: 'Attribute Max' }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          label={setLanguageText(language, "Rule Name")}
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name={`two.attr_rules[${index}].output`}
                      control={control}
                      defaultValue={item.output}
                      //rules={{ required: 'Attribute Max' }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          label={setLanguageText(language, "Rule Output Value")}
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="attr_status_label">
                        {setLanguageText(language, "State/Colour")}
                      </InputLabel>
                      <Controller
                        name={`two.attr_rules[${index}].status`}
                        control={control}
                        rules={{ required: "Status Required" }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            label={setLanguageText(language, "Status")}
                            labelId="attr_status_label"
                          >
                            {currentVariables &&
                              currentVariables.map((item) => {
                                //console.log(item)
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    <Chip
                                      icon={null}
                                      style={{
                                        marginLeft: "10px",
                                        backgroundColor: item.colour
                                          ? item.colour.hex
                                          : "transparent",
                                      }}
                                      label={<span>{item.name}</span>}
                                    />
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        defaultValue={item.status} // make sure to set up defaultValue
                      />
                    </FormControl>
                  </Grid>
                  <LogicForm
                    register={register}
                    control={control}
                    fieldName={fieldName}
                  />
                </Grid>
              </CardContent>
              <CardActions>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => remove(index)}
                  >
                    {setLanguageText(language, "Remove Rule")}
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            append({
              name: setLanguageText(language, "rule name"),
              label: setLanguageText(language, "rule label"),
              colour: setLanguageText(language, "rule colour"),
            })
          }
        >
          {setLanguageText(language, "Add Rule")}
        </Button>
      </Grid>
    </Grid>
  );
};

export const FormThree = ({ compiledForm }) => {
  const { language } = useContext(LayoutContext);
  const { watch, control } = useFormContext();
  const watchFields = watch(["three.attr_form_output"]);
  //console.log(watchFields)

  const { fields, append, remove } = useFieldArray({
    control,
    name: "three.attr_form_options",
    // this is an array of objects {title: '', id: ''}
  });

  // useEffect(() => {
  //   //console.log(compiledForm)
  //   if (compiledForm.three) {
  //     reset({ ...compiledForm.three });
  //   }
  // }, [reset, compiledForm.three]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="attr_form_output-label">
            Form Output Type
          </InputLabel>
          <Controller
            name="three.attr_form_output"
            control={control}
            rules={{ required: "Form Output Type Required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                label={setLanguageText(language, "Form Output Type")}
                labelId="attr_form_output-label"
              >
                <MenuItem value="number">
                  {setLanguageText(language, "Number Field")}
                </MenuItem>
                <MenuItem value="radio">
                  {setLanguageText(language, "Radio Select")}
                </MenuItem>
                <MenuItem value="dial">
                  {setLanguageText(language, "Dial")}
                </MenuItem>
              </Select>
            )}
            defaultValue="" // make sure to set up defaultValue
          />
        </FormControl>
      </Grid>
      {watchFields && watchFields[0] === "radio" && (
        <Grid container item spacing={2} alignContent="center">
          {fields.map((item, index) => {
            //const LogicName = `logic[${index}]`;
            return (
              <Grid key={item.id} container item spacing={2}>
                <Grid item xs={3}>
                  <Controller
                    name={`three.attr_form_options[${index}].output`}
                    control={control}
                    defaultValue={item.output}
                    //rules={{ required: 'Attribute Max' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label={setLanguageText(language, "Label")}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {/* <TextField
                        fullWidth
                        name={`attr_form_options[${index}].output`}
                       label={setLanguageText(language,"Label"
                        variant="outlined"
                        inputRef={register()}
                        defaultValue={item.output}
                      /> */}
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`three.attr_form_options[${index}].value`}
                    control={control}
                    defaultValue={item.value}
                    //rules={{ required: 'Attribute Max' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        label={setLanguageText(language, "Value")}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {/* <TextField
                        fullWidth
                        name={`attr_form_options[${index}].value`}
                       label={setLanguageText(language,"Value"
                        variant="outlined"
                        inputRef={register()}
                        defaultValue={item.value}
                      /> */}
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name={`three.attr_form_options[${index}].tooltip`}
                    control={control}
                    defaultValue={item.tooltip}
                    //rules={{ required: 'Attribute Max' }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        multiline={true}
                        label={setLanguageText(language, "Tooltip")}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="attr_form_output-severity">
                      Tooltip severity Level
                    </InputLabel>
                    <Controller
                      name={`three.attr_form_options[${index}].severity`}
                      control={control}
                      //rules={{ required: 'Form Output Type Required' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          label={setLanguageText(
                            language,
                            "Tooltip severity Level"
                          )}
                          labelId="attr_form_output-severity"
                        >
                          <MenuItem value="success">
                            {setLanguageText(language, "Low")}
                          </MenuItem>
                          <MenuItem value="warning">
                            {setLanguageText(language, "Medium")}
                          </MenuItem>
                          <MenuItem value="error">
                            {setLanguageText(language, "High")}
                          </MenuItem>
                        </Select>
                      )}
                      defaultValue={item.severity} // make sure to set up defaultValue
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => remove(index)}
                  >
                    {setLanguageText(language, "Remove Option")}
                  </Button>
                </Grid>
              </Grid>
            );
          })}
          <Grid container item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => append({ label: "", value: "" })}
            >
              {setLanguageText(language, "Add Option")}
            </Button>
            <Divider />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const LogicForm = ({ control, fieldName }) => {
  const { language } = useContext(LayoutContext);
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${fieldName}.logic`,
  });

  return (
    <Grid container item xs={12} spacing={2}>
      {fields.map((item, index) => {
        //const LogicName = `logic[${index}]`;
        return (
          <Grid key={item.id} container item xs={12} spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="operator-label">
                  {setLanguageText(language, "State/Colour")}
                </InputLabel>
                <Controller
                  name={`${fieldName}.logic[${index}].operator`}
                  control={control}
                  //rules={{ required: 'Required' }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      label={setLanguageText(language, "Operator")}
                      labelId="operator-label"
                    >
                      <MenuItem value=">">
                        {setLanguageText(language, "Greater than")}
                      </MenuItem>
                      <MenuItem value=">=">
                        {setLanguageText(language, "Greater than & Equal to")}
                      </MenuItem>
                      <MenuItem value="==">
                        {setLanguageText(language, "Equal to")}
                      </MenuItem>
                      <MenuItem value="<">
                        {setLanguageText(language, "Lower than")}
                      </MenuItem>
                      <MenuItem value="<=">
                        {setLanguageText(language, "Lower than & Equal to")}
                      </MenuItem>
                    </Select>
                  )}
                  defaultValue={item.operator} // make sure to set up defaultValue
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`${fieldName}.logic[${index}].value`}
                control={control}
                defaultValue={item.value}
                rules={{ required: "Value Required" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    label={setLanguageText(language, "Value")}
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="connection-label">Connection</InputLabel>
                <Controller
                  name={`${fieldName}.logic[${index}].connection`}
                  control={control}
                  //rules={{ required: 'Required' }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      label={setLanguageText(language, "Connection")}
                      labelId="connection-label"
                    >
                      <MenuItem value="&&">
                        {setLanguageText(language, "AND")}
                      </MenuItem>
                      <MenuItem value="||">
                        {setLanguageText(language, "OR")}
                      </MenuItem>
                      <MenuItem value="">
                        {setLanguageText(language, "NONE")}
                      </MenuItem>
                    </Select>
                  )}
                  defaultValue={item.connection} // make sure to set up defaultValue
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => remove(index)}
              >
                {setLanguageText(language, "Remove Logic")}
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Grid container item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => append({ operator: "", value: "", connection: "" })}
        >
          {setLanguageText(language, "Add Logic")}
        </Button>
        <Divider />
      </Grid>
    </Grid>
  );
};

export const FormFour = ({ compiledForm }) => {
  const { language } = useContext(LayoutContext);
  const { control } = useFormContext();

  // useEffect(() => {
  //   //console.log(compiledForm)
  //   if (compiledForm.four) {
  //     reset({ ...compiledForm.four });
  //   }
  // }, [reset, compiledForm.four]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="attr_chart_label">
            {setLanguageText(language, "Chart Type")}
          </InputLabel>
          <Controller
            name="four.attr_chart"
            control={control}
            rules={{ required: "Chart Type Required" }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                label={setLanguageText(language, "Chart Type")}
                labelId="attr_chart_label"
              >
                <MenuItem value="pie">
                  {setLanguageText(language, "Pie")}
                </MenuItem>
                <MenuItem value="bar">
                  {setLanguageText(language, "Bar")}
                </MenuItem>
              </Select>
            )}
            defaultValue="" // make sure to set up defaultValue
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
