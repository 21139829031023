import React, { useState } from 'react';
import { Grid, Hidden, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import ResponsiveMenu from './ResponsiveMenu/ResponsiveMenu';
import NavigationButtons from './NavigationButtons/NavigationButton';
import { isMobileOnly } from 'react-device-detect';
import config from '../../config';
import LanguageSelect from './LanguageSelect';

const useStyles = makeStyles(theme => ({
  header: {
    height: theme.spacing(10),
    padding: theme.spacing(2, 3),
    // marginLeft: theme.spacing(12.5),
    background: theme.palette.primary.main,
    transition: 'background .75s',
    zIndex: 15,
    //paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      padding: isMobileOnly ? theme.spacing(1) : theme.spacing(2),
      marginLeft: 0
    },
    position: 'fixed'
  },
  logoContainer: {
    height: '100%',
    padding: 0,
    marginTop: 0
  },
  companyLogo: {
    paddingleft: '0!important',
    paddingTop: '0!important',
    height: '100%',
    '& a': {
      width: '100%',
      '& img': {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    }
  },
  companyText: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none'
  }
}));
const Header = () => {

  const headerStyle = useStyles();
  const location = useLocation();

  let app = config.APPNAME
  const [logo, setLogo] = useState('');
  import(`../../image/${app}-logo-small.png`).then((module) => {
    setLogo(module.default);
  });

  return location.pathname !== '/displayView' ? (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="space-between"
      className={headerStyle.header}
    >
      <Grid item container alignItems="center"
        alignContent="center" xs={10} md={4} spacing={2}
        className={headerStyle.logoContainer}>
        <Grid item className={`${headerStyle.companyLogo} logo`}>
          <Link to="/">
            {logo && <img height="100%" src={logo} alt="logo" />}
          </Link>
        </Grid>
        {config.TITLE &&
          <Hidden mdDown>
            <Grid item>
              <Link to="/" className={headerStyle.companyText}>
                <Typography variant="h5">{config.TITLE}</Typography>
              </Link>
            </Grid>
          </Hidden>
        }
      </Grid>
      <Hidden mdDown>
        <NavigationButtons />
      </Hidden>
      <Hidden mdUp>
        <ResponsiveMenu />
      </Hidden>
    </Grid>
  ) : null;
};

export default Header;
